import React from 'react'

import { Layout } from 'ethos-design-system'
import PropTypes from 'prop-types'

import styles from './ColoredPaddedContainer.module.scss'

const ColoredPaddedContainer = ({ color, className, children }: any) => (
  <div className={styles[color]}>
    <Layout.HorizontallyPaddedContainer className={className}>
      {children}
    </Layout.HorizontallyPaddedContainer>
  </div>
)

ColoredPaddedContainer.COLORS = [
  'sand',
  'salamander',
  'forest',
  'duckegg',
  'buttercup',
  'moss',
  'white',
]

ColoredPaddedContainer.propTypes = {
  color: PropTypes.oneOf(ColoredPaddedContainer.COLORS),
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
}

ColoredPaddedContainer.defaultProps = {
  color: 'white',
}

export default ColoredPaddedContainer
